const openNewWindow = {
  links: document.querySelectorAll( '.open-in-new-window' ),

  init() {
    // Blog articles
    const articleLinks = [
      ...document.querySelectorAll( 'a[href^="https://articles."], a[href^="http://articles."]' )
    ];
    articleLinks.forEach( link => {
      link.addEventListener( 'click', ( e ) => {
        e.preventDefault();
        this.openInNewWindow( link.href );
      } );
    } );

    // With class
    if ( this.links.length === 0 ) return;
    this.links.forEach( link => {
      link.addEventListener( 'click', ( e ) => {
        e.preventDefault();
        const url = link.querySelector( ':scope > a:not([href*="//articles."])' ).href;
        this.openInNewWindow( url );
      } );
    } );
  },

  openInNewWindow( url ) {
    window.open( url, '_blank', 'location=yes,height=740,width=640,scrollbars=yes,status=yes' );
  }
};
openNewWindow.init();

import Swiper from 'swiper';

const fourCardsSlider = {
  init( sectionClass, wrapperClass, slideClass, slideGap=20 ) {
    const sections = document.querySelectorAll( sectionClass );

    if ( sections.length === 0 ) return;

    sections.forEach( section => {
      const wrapper = section.querySelector( wrapperClass );
      wrapper.classList.add( 'swiper' );

      const swiperWrapper = document.createElement( 'div' );
      swiperWrapper.classList.add( 'swiper-wrapper' );

      const slides = wrapper.querySelectorAll( slideClass );
      slides.forEach( slide => {
        slide.classList.add( 'swiper-slide' );
        swiperWrapper.appendChild( slide );
      } );
      wrapper.appendChild( swiperWrapper );

      this.createProgressBar( wrapper ); // Create Progress bar

      this.initSwiper( wrapper, slideGap ); // Initialize swiper
    } );
  },

  /**
   * Create Progress bar
   * @param {Element} wrapper 
   */
  createProgressBar( wrapper ) {
    const progressBarWrapper = document.createElement( 'div' );
    progressBarWrapper.classList.add( 'swiper-progress-bar-wrapper' );
    progressBarWrapper.innerHTML = `
      <div class="swiper-progress-bar">
        <div class="swiper-progress-fill"></div>
      </div>
      <div class="swiper-slide-count"></div>`;
    wrapper.appendChild( progressBarWrapper );
  },

  /**
   * Initialize Swiper
   */
  initSwiper( swiperSelector, slideGap ) {
    new Swiper( swiperSelector, { // NOSONAR
      slidesPerView: 1,
      spaceBetween: slideGap,
      loop: false,
      on: {
        slideChange: ( swiper ) => {
          console.log( swiper );
          this.updateProgress( swiper );
        },
      },
    } );
    this.updateProgress( swiperSelector.swiper );
  },

  /**
   * Update progress bar
   */
  updateProgress( swiper ) {
    const progressBarFill = swiper.el.querySelector( '.swiper-progress-fill' );
    const slideCount = swiper.el.querySelector( '.swiper-slide-count' );
    const currentIndex = swiper.realIndex + 1;
    const totalSlides = swiper.slides.length;
    progressBarFill.style.width = `${ ( currentIndex / totalSlides ) * 100 }%`;
    slideCount.innerText = `${ currentIndex }/${ totalSlides }`;
  }
};

document.addEventListener( 'DOMContentLoaded', () => {
  if ( window.innerWidth < 768 ) {
    fourCardsSlider.init( '.e-stn--four-cards', '.e-div--four-cards-wrapper', '.e-div--four-card' );
    fourCardsSlider.init( '.e-stn--video-blocks', '.e-col--cards-wrapper', '.e-crd', 15 );
    fourCardsSlider.init( '.e-stn--left-right-content-cards-v2', '.container', '.e-row', 15 );
  }
} );

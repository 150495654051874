document.addEventListener( 'DOMContentLoaded', () => {
  const buttons = document.querySelectorAll( 
    '.e-stn--main-hero-banner .e-btn--round, .e-stn--inner-page-banner .e-btn--round' );
  let activeIndex = 0;
  let interval;
  const startLoop = () => {
    if ( buttons.length === 0 ) return;
    const startInterval = () => {
      interval = setInterval( () => {
        buttons.forEach( ( btn, id ) => {
          btn.classList.remove( 'active' );
          btn.setAttribute( 'data-index', id );
        } ); // Remove active from all
        buttons[activeIndex].classList.add( 'active' ); // Add active to current button
        activeIndex = ( activeIndex + 1 ) % buttons.length; // Move to next button
      }, 3000 ); // Change every 3 seconds
    };

    const stopInterval = () => {
      clearInterval( interval );
      document.querySelector( '.e-btn--round.active' ).classList.remove( 'active' );
    };

    buttons.forEach( button => {
      button.addEventListener( 'mouseenter', stopInterval ); // Stop on hover
      button.addEventListener( 'mouseleave', ( e ) => {
        e.target.classList.add( 'active' );
        activeIndex = e.target.dataset['index'];
        startInterval();
      } ); // Resume on leave
    } );

    startInterval(); // Start the animation loop
  };
  startLoop();
} );

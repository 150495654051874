class ElementObserver {
  constructor( selectors ) {
    this.selectors = selectors;
  }

  init() {
    const elements = document.querySelectorAll( this.selectors );
    const bottomRootMargin =
      window.innerHeight > window.innerWidth ? '-8%' : '-16%';
      
    elements.forEach( ( element ) => {
      const observer = new IntersectionObserver(
        ( entries, observer ) => {
          const entry = entries[0];
          if ( entry.isIntersecting ) {
            element.classList.add( 'e-appeared' );
            observer.disconnect();
          }
        },
        {
          root: null,
          rootMargin: `0px 0px ${bottomRootMargin} 0px`,
        }
      );
      observer.observe( element );
    } );
  }
}

// Initialize with the desired selectors
const observedEls = new ElementObserver(
  '.e-stn--observe,.e-div--observe,.e-crd--observe,.e-col--observe,.e-row--observe'
);
observedEls.init();

/* eslint-disable no-unused-vars */
const createBubble = () => {
  const wrapper = document.querySelector( '.e-div--search-wrapper' );
  if ( !wrapper ) return; // Prevent errors if wrapper is missing

  const bubble = document.createElement( 'div' );
  bubble.classList.add( 'bubble' );

  // fix sonar issue
  const getRandomInRange = ( min, max, decimals = 2 ) => 
    Number( ( Math.random() * ( max - min ) + min ).toFixed( decimals ) ); //NOSONAR

  // Generate random size and animation duration
  const size = `${getRandomInRange( 5, 25 )}px`;
  const randomDuration = getRandomInRange( 3, 6, 1 ); // Between 3s - 6s
  const leftPosition = `${getRandomInRange( 0, wrapper.offsetWidth, 0 )}px`;

  // Apply styles
  Object.assign( bubble.style, {
    width: size,
    height: size,
    left: leftPosition,
    animationDuration: `${randomDuration}s`
  } );

  wrapper.appendChild( bubble );

  // Remove bubble after animation ends
  setTimeout( () => bubble.remove(), randomDuration * 1000 );
};

const createRipples = () => {
  const wrapper = document.querySelector( '.e-div--search-wrapper' );
  if ( !wrapper ) return; // Prevent errors if wrapper is missing

  const rippleWrapper = document.createElement( 'div' );
  rippleWrapper.classList.add( 'ripple-wrapper' );

  for ( let index = 1; index < 4; index++ ) {
    const ripple = document.createElement( 'div' );
    ripple.classList.add( 'ripple', `ripple-${index}` );
    wrapper.appendChild( ripple );
  }
};

// Generate bubbles at intervals
setInterval( createBubble, 100 );

// create Ripples
// createRipples();

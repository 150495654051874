function updateMainClass() {
  // Check if any element in the document has the mega-toggle-on class
  const hasMegaToggleOn = !!document.querySelector( '.mega-toggle-on' );
  const mainElement = document.querySelector( 'main' );

  if ( mainElement ) {
    if ( hasMegaToggleOn ) {
      mainElement.classList.add( 'menu-active' );
    } else {
      mainElement.classList.remove( 'menu-active' );
    }
  }
}

// Function to observe specific elements for class changes
function observeMegaToggle() {
  const targetElements = document.querySelectorAll( '.mega-menu-item' );

  targetElements.forEach( ( element ) => {
    const observer = new MutationObserver( () => {
      updateMainClass(); // Call the update function when a mutation occurs
    } );

    // Start observing the element for attribute changes
    observer.observe( element, {
      attributes: true, // Watch for changes in attributes
      attributeFilter: ['class'], // Specifically monitor class changes
    } );
  } );
}

// Call the observer function when the page loads
document.addEventListener( 'DOMContentLoaded', observeMegaToggle );

document.addEventListener( 'DOMContentLoaded', function () {
  const header = document.querySelector( 'header' );
  const megaMenus = header.querySelectorAll(
    '.e-div--header-logo-with-nav ul.mega-menu .mega-menu-description, ' +
    '.e-div--header-logo-with-nav ul.mega-menu .mega-menu-link:not(:has(.mega-description-group))'
  );

  const addArrowIcon = () => {
    megaMenus.forEach( ( megaMenu ) => {
      if ( megaMenu && megaMenu.textContent.trim().length > 0 ) {
        const text = megaMenu.textContent.trim();
        const lastChar = text.slice( -1 );
        const span = document.createElement( 'span' );
        span.className = 'arrow-icon';
        span.textContent = lastChar;
        megaMenu.textContent = text.slice( 0, -1 );
        megaMenu.appendChild( span );
      }
    } );
  };

  const removeArrowIcon = () => {
    megaMenus.forEach( ( megaMenu ) => {
      if ( megaMenu ) {
        const text = megaMenu.textContent.replace( /<span class="arrow-icon">(.+?)<\/span>/g, '$1' );
        megaMenu.textContent = text;
      }
    } );
  };

  const handleScreenSizeChange = ( event ) => {
    if ( event.matches ) {
      addArrowIcon();
    } else {
      removeArrowIcon();
    }
  };

  const mediaQuery = window.matchMedia( '(min-width: 1200px)' );
  handleScreenSizeChange( mediaQuery );
  mediaQuery.addEventListener( 'change', handleScreenSizeChange );

  const handleScroll = () => {
    if ( window.scrollY > 30 ) {
      header.classList.add( 'scrolled' );
    } else {
      header.classList.remove( 'scrolled' );
    }
  };

  window.addEventListener( 'scroll', handleScroll );
  handleScroll();

  window.addEventListener( 'unload', () => {
    window.removeEventListener( 'scroll', handleScroll );
    mediaQuery.removeEventListener( 'change', handleScreenSizeChange );
  } );
} );

/* eslint-disable no-unused-vars */
const accordionScroll = ( eles, scrollAlways = false ) => {
  const accordions = eles;
  if( accordions.length > 0 ) {
    accordions.forEach( acco => {
      const accoHeader = acco.querySelector( '.e-acc__itm-hdr-wrpr' );
      accoHeader.addEventListener( 'click', () => {
        setTimeout( () => {
          const accoHeaderTop = accoHeader.getBoundingClientRect().top;
          const hH = document.querySelector( 'header' )?.offsetHeight || 0;
          const scrollIf = scrollAlways ? scrollAlways : accoHeaderTop < hH ;
          if( scrollIf ) {
            window.scrollTo( {
              top: ( accoHeaderTop + window.scrollY ) - ( hH + 25 ) ,
              behavior: 'smooth'
            } );
          }
        }, 700 );
      } );
    } );
  }
};
const accordionEls = document.querySelectorAll( '.e-acc--scroll-to-top .e-acc__itm-wrpr' );
if ( accordionEls ) {
  accordionScroll( accordionEls );
}
const tocAccordionEls = document.querySelectorAll( '.e-acc--toc-accordion .e-acc__itm-wrpr' );
if ( accordionEls ) {
  accordionScroll( tocAccordionEls, true );
}

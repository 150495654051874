document.addEventListener( 'DOMContentLoaded', function () {
  const micrositeParent = document.querySelector( '.microsite-parent' );
  const micrositeImage = document.querySelector( '.microsite-parent .e-stn--microsite-banner .e-bic' );
  const micrositeSlider = document.querySelector( '.microsite-parent .e-stn--microsite-banner .e-sld' );
  const micrositeHeading = document.querySelector( '.microsite-parent .e-stn--microsite-banner h1.wp-block-heading' );
  const contentWrapper = document.querySelector( '.microsite-parent .e-stn--microsite-banner .e-div--content-wrapper' );
  const listGrid = document.querySelector( '.microsite-parent .e-stn--microsite-banner .e-div--list-grid' );
  const Breadcrumb = document.querySelector( '.microsite-parent .e-stn--three-breadcrumb .e-div--three-breadcrumb' );

  function rearrangeElements( e ) {
    if ( e.matches ) {
      if ( micrositeParent && micrositeImage && micrositeHeading ) {
        micrositeHeading.insertAdjacentElement( 'afterend', micrositeImage );
      }

      if ( micrositeParent && micrositeSlider && micrositeHeading ) {
        micrositeHeading.insertAdjacentElement( 'afterend', micrositeSlider );
      }

      if ( contentWrapper && listGrid ) {
        listGrid.insertAdjacentElement( 'beforebegin', contentWrapper );
      }

      if ( contentWrapper && Breadcrumb ) {
        contentWrapper.appendChild( Breadcrumb );
      }
    }
  }

  const mediaQuery = window.matchMedia( '(max-width: 767px)' );

  rearrangeElements( mediaQuery );

  mediaQuery.addEventListener( 'change', rearrangeElements );

  // Pause slider autoplay on hover
  function pauseSliderAutoplay() {
    const slider = micrositeSlider.querySelector( '.swiper' );
    const slides = slider.querySelectorAll( '.swiper-slide' );

    if ( !slider || slides.length === 0 ) return;

    slides.forEach( slide => {
      const video = slide.querySelector( '.e-vid' );
      const popup = slide.querySelector( '[data-fancybox]' );
      if ( video || popup ) {
        slide.addEventListener( 'mouseenter', () => {
          slider.swiper.autoplay.stop();
        } );
        slide.addEventListener( 'mouseleave', () => {
          slider.swiper.autoplay.start();
        } );
      }
    } );
  }
  pauseSliderAutoplay();
} );

if (
  document.body.classList.contains( 'search-results' ) && 
    document.body.classList.contains( 'transparent-header' )
) {
  document.body.classList.replace( 'transparent-header', 'white-header' );
}

if (
  document.body.classList.contains( 'search-no-results' )
) {
  document.body.classList.add( 'white-header' );
}
  
( () => {
  /*eslint-disable no-undef,no-unused-vars*/
  document.addEventListener( 'DOMContentLoaded', () => {
    // Check if the element with id "office-directory" exists
    const officeDirectoryElement = document.getElementById( 'office-directory' );
    if ( !officeDirectoryElement ) {
      return;
    }

    if ( typeof autocompleteDirectoryData === 'undefined' ) {
      console.error( 'autocompleteDirectoryData is not defined.' );
      return;
    }

    if ( typeof officeDirectoryMapId === 'undefined' ) {
      console.error( 'officeDirectoryMapId is not defined.' );
      return;
    }

    if ( typeof locationProximityRadius === 'undefined' ) {
      console.error( 'locationProximityRadius is not defined.' );
      return;
    }

    if ( typeof isOfficeDirectoryFilter === 'undefined' ) {
      console.error( 'isOfficeDirectoryFilter is not defined.' );
      return;
    }

    if ( typeof iMapsManager === 'undefined' ) {
      console.error( 'iMapsManager is not defined.' );
      return;
    }

    const dataSource = autocompleteDirectoryData;
    const apiKey = googleMapGeoLocationAPIKey;
    const geocodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
    const searchInput = document.getElementById( 'search' );
    const searchClearButton = document.getElementById( 'search-clear' );
    const stateSelect = document.getElementById( 'state' );
    const useMyLocationBtn = document.getElementById( 'use-my-location' );
    const resultRow = document.getElementById( 'post-results-row' );

    document.addEventListener( 'click', ( event ) => {
      const loadMoreButton = document.getElementById( 'location-load-more-button' );
      if ( event.target.closest( '#location-load-more-button' ) ) {
        event.preventDefault();
        const extraPosts = document.querySelectorAll( '.location-post.extra-post' );
        // Show all hidden posts
        extraPosts.forEach( post => post.style.display = 'block' );
        // Hide the Load More button
        loadMoreButton.style.display = 'none';
      }
    } );

    // Function to get the user's location and auto-select the state
    function detectUserLocation() {
      if ( 'geolocation' in navigator ) {
        navigator.geolocation.getCurrentPosition(
          ( position ) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            const urlWithParams = `${geocodeUrl}?latlng=${lat},${lng}&key=${apiKey}`;
            fetch( urlWithParams )
              .then( response => response.json() )
              .then( data => {
                if ( data && data.results && data.results.length > 0 ) {
                  const result = data.results[0];
                  const addressComponents = result.address_components;
                  let country = '';
                  let stateCode = '';

                  // Loop through address components to get country and state code
                  addressComponents.forEach( component => {
                    if ( component.types.includes( 'country' ) ) {
                      country = component.short_name; // Example: "US"
                    }
                    if ( component.types.includes( 'administrative_area_level_1' ) ) {
                      stateCode = component.short_name; // Example: "CA"
                    }
                  } );
                  if ( country === 'US' ) {
                    // Set the state dropdown to the detected state
                    const stateSelect = document.getElementById( 'state' );
                    const stateOption = Array.from( stateSelect.options ).find(
                      option => option.value === stateCode
                    );
                    let selectedItem = {
                      value: stateCode,
                      key: 'state',
                    };
                    if ( stateOption ) {
                      stateOption.selected = true;
                      selectedItem = {
                        value: stateOption.value,
                        key: 'state',
                      };
                    }
                    isOfficeDirectoryFilter = true;
                    iMapsManager.select( officeDirectoryMapId, 'US-'+stateCode );
                    officeDirectoryDataLoadByAjaxCall( selectedItem );
                  } else {
                    resultRowMessageDisplay( 'You are not in the USA.', true );
                  }
                }
              } )
              .catch( error => {
                const message = 'Error with reverse geocoding';
                console.error( message, error );
                resultRowMessageDisplay( message, true );
              } );
          },
          ( error ) => {
            const message = 'Geolocation error';
            console.error( message, error );
            resultRowMessageDisplay( message, true );
          }
        );
      } else {
        const message = 'Geolocation not supported by this browser.';
        console.error( message );
        resultRowMessageDisplay( message, true );
      }
    }

    // Function to filter data based on user input
    function filterAutocompleteDirectoryDataByText( query ) {
      return dataSource.filter( item =>
        item.display.toLowerCase().includes( query.toLowerCase() )
      ).map( item => ( { ...item, method: 'text matching'} ) );
    }

    // Function to filter data based on nearby
    function filterAutocompleteDirectoryDataNearBy( query ) {
      const urlWithParams = `${geocodeUrl}?address=${query}&components=country:US&key=${apiKey}`;

      return fetch( urlWithParams )
        .then( response => response.json() )
        .then( data => {
          if ( data.results && data.results.length > 0 && !data.results[0].types.includes( 'country' ) ) {
            const center = {
              lat: data.results[0].geometry.location.lat,
              lng: data.results[0].geometry.location.lng
            };
            return getLocationsWithinRadius( center, dataSource, locationProximityRadius );
          } else {
            return []; // Return empty array if no location was found
          }
        } )
        .catch( error => {
          console.error( 'Unable to geocode location', error );
          return []; // Return empty array on error
        } );
    }

    function getLocationsWithinRadius( center, locations, radiusKm ) {
      const earthRadiusKm = 6371; // Radius of the Earth in kilometers

      // Haversine formula to calculate distance between two coordinates
      function haversineDistance( lat1, lon1, lat2, lon2 ) {
        const toRadians = ( degrees ) => degrees * ( Math.PI / 180 );
        const dLat = toRadians( lat2 - lat1 );
        const dLon = toRadians( lon2 - lon1 );

        const a =
          Math.sin( dLat / 2 ) * Math.sin( dLat / 2 ) +
          Math.cos( toRadians( lat1 ) ) * Math.cos( toRadians( lat2 ) ) *
          Math.sin( dLon / 2 ) * Math.sin( dLon / 2 );

        const c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
        return earthRadiusKm * c;
      }

      // Filter locations within the radius and calculate distance
      const locationsWithinRadius = locations
        .map( location => ( {
          ...location,
          distance: haversineDistance( center.lat, center.lng, location.lat, location.lng )
        } ) )
        .filter( location => location.distance <= radiusKm ) // Only keep locations within radius
        .sort( ( a, b ) => a.distance - b.distance ); // Sort by distance

      return locationsWithinRadius;
    }

    // Function to input box search
    function searchByInputBox( results ) {
      const idsArray = results.map( item => item.id );
      officeDirectoryDataLoadByAjaxCall( {key: 'ids', value: idsArray} );
    }

    // Result Row Message Display
    function resultRowMessageDisplay( message = '' , autoHide = false ) {
      const resultRowMessageElement = document.createElement( 'p' );
      resultRowMessageElement.textContent = message;
      resultRowMessageElement.classList.add( 'no-result' );
      if ( autoHide ) {
        resultRowMessageElement.classList.add( 'auto-hide' );
        setTimeout( () => {
          resultRowMessageElement.remove();
        }, 3000 );
      }
      resultRow.innerHTML = '';
      resultRow.appendChild( resultRowMessageElement );
    }

    function clearSearchInput() {
      // Abort any ongoing AJAX request
      if ( lastFetchController ) {
        lastFetchController.abort();
        lastFetchController = null;
      }
    }

    // Add event listener for the state select box
    stateSelect.addEventListener( 'change', ( e ) => {
      searchInput.value = '';
      const selectedOption = e.target.selectedOptions[0];
      const value = selectedOption.value;

      // Trigger the same AJAX call with the selected value and key
      const selectedItem = {
        value: value,
        key: 'state',
        map_state_code: selectedOption.dataset.mapStateCode
      };
      isOfficeDirectoryFilter = true;
      iMapsManager.select( officeDirectoryMapId, selectedOption.dataset.mapStateCode );
      officeDirectoryDataLoadByAjaxCall( selectedItem );
      scrollToOfficeDirectoryTop();
    } );

    // Function to handle search execution
    const executeSearch = ( query ) => {
      if ( query.length > 2 ) {
        const textMatchingResults = filterAutocompleteDirectoryDataByText( query );

        // Fetch nearby results asynchronously and merge them
        filterAutocompleteDirectoryDataNearBy( query ).then( nearbyResults => {
          const mergedResults = [
            ...textMatchingResults,
            ...nearbyResults.map( item => ( { ...item, method: 'nearby' } ) )
          ];

          // Filter out duplicate results
          const uniqueResults = mergedResults.filter( ( item, index, self ) =>
            index === self.findIndex( t => t.id === item.id && t.display === item.display )
          );

          searchByInputBox( uniqueResults );
        } );
      } else {
        clearSearchInput();
        if ( !document.querySelector( '#office-directory #location-load-more-button' ) ) {
          officeDirectoryDataLoadByAjaxCall( { key: 'all', value: null } );
        }
      }
    };

    // Event listener for Enter key press
    searchInput.addEventListener( 'keydown', ( e ) => {
      if ( e.key === 'Enter' ) {
        e.preventDefault(); // Prevent default form submission
        executeSearch( e.target.value.trim() );
      }
    } );

    // Event listener for input changes (keyup)
    searchInput.addEventListener( 'keyup', ( e ) => {
      const query = e.target.value.trim();
      searchClearButton.style.display = query.length > 0 ? 'block' : 'none';

      if ( !query.length ) {
        searchClearButton.click();
      }
    } );

    searchClearButton.addEventListener( 'click', ( e ) => {
      e.preventDefault(); // Prevent default anchor behavior
      searchInput.value = '';
      setTimeout( () => searchInput.focus(), 10 );
      scrollToOfficeDirectoryTop();
      officeDirectoryDataLoadByAjaxCall( {key: 'all', value: null} );
      searchClearButton.style.display = 'none';

    } );

    // Call detectUserLocation when "Use my location" is clicked
    useMyLocationBtn.addEventListener( 'click', ( e ) => {
      e.preventDefault(); // Prevent default anchor behavior
      detectUserLocation();
      scrollToOfficeDirectoryTop();
    } );

    const mapContainer = document.getElementById( 'map_' + officeDirectoryMapId );
    mapContainer.addEventListener( 'mapready',( )=>{
      // Get the available region codes on Map
      const availableRegionCodesOnMap = iMapsManager.getRegionsByValue( officeDirectoryMapId )
        .map( item => item.dataItem._dataContext.id );

      // Get the <select> element by ID
      let selectElement = document.getElementById( 'state' );

      // Loop through each option in the select element
      Array.from( selectElement.options ).forEach( option => {
        // If the option's data-map-state-code attribute is not in the available region codes, remove it
        const optionMapStateCode = option.getAttribute( 'data-map-state-code' );
        if ( optionMapStateCode && !availableRegionCodesOnMap.includes( optionMapStateCode ) ) {
          option.remove();
        }
      } );
    } );
  } );
  /*eslint-enable no-undef,no-unused-vars*/
} ) ();
/* eslint-disable no-unused-vars */
class ParallaxMove {
  constructor( element, options = {} ) {
    const defaultSettings = {
      offset: 0,
      mobile: false,
      dir: 'top',
      headerFixed: false,
      twoWay: false,
      parentEl: 'section',
    };

    // Merge default settings with user options
    this.settings = { ...defaultSettings, ...options };
    this.element = element;
    this.hH = document.querySelector( 'header' )?.offsetHeight || 0;
    this.stopPos = this.settings.headerFixed ? this.hH : 0;
  }

  init() {
    const wH = window.innerHeight;
    const wW = window.innerWidth;
    const hH = this.hH;
    const parent = this.element.closest( this.settings.parentEl );
    const boundingBox = parent.getBoundingClientRect();
    const parentTop = boundingBox.top;
    const parentBottom = boundingBox.bottom;
    const speed = this.settings.headerFixed ? this.settings.offset / ( wH - hH ) : this.settings.offset / wH;
    const inViewHeight = wH - parentTop;
    const checkTwoWay = !this.settings.twoWay ? parentTop >= this.stopPos : parentTop >= boundingBox.height * -1;
    const scrollPortion = 
      this.settings.dir === 'top' || this.settings.dir === 'right'
        ? this.settings.offset - inViewHeight * speed
        : ( this.settings.offset - inViewHeight * speed ) * -1;
    const disableParallax = wW < 768 && !this.settings.mobile;
    // Check if element is within the viewport and apply parallax
    if ( parentTop <= parseInt( wH + 100 ) && parentBottom >= 0 && checkTwoWay && !disableParallax ) {
      if ( this.settings.dir === 'top' ) {
        this.element.style.transform = `translate3d(0, ${scrollPortion}px, 0)`;
      } else if ( this.settings.dir === 'right' ) {
        this.element.style.transform = `translate3d(${scrollPortion}px, 0, 0)`;
      }
      this.element.classList.add( 'parallax-init' );
    } else if ( !this.settings.twoWay && parentTop <= 0 ) {
      if ( !this.element.classList.contains( 'parallax-init' ) ) {
        this.element.style.transform = 'translate3d(0, 0, 0)';
      }
    } else if ( disableParallax ) {
      this.element.style.removeProperty( 'transform' );
    }

    this.element.classList.add( 'parallax-item' );
  }
}

const handleCtaMediaEvent = () => {
  const ctaMediaImage1 = document.querySelector( '.e-img--cta-media-img-1' );
  const ctaMediaImage2 = document.querySelector( '.e-img--cta-media-img-2' );
  const ctaMediaImage3 = document.querySelector( '.e-img--cta-media-img-3' );
  if( ctaMediaImage1 ) {
    const ctaMediaImage1Parallax = new ParallaxMove( ctaMediaImage1, {
      offset: -100,
      headerFixed: true
    } );
    ctaMediaImage1Parallax.init();
  }
  if( ctaMediaImage2 ) {
    const ctaMediaImage2Parallax = new ParallaxMove( ctaMediaImage2, {
      offset: -50,
      headerFixed: true
    } );
    ctaMediaImage2Parallax.init();
  }
  if( ctaMediaImage3 ) {
    const ctaMediaImage3Parallax = new ParallaxMove( ctaMediaImage3, {
      offset: 50,
      headerFixed: true
    } );
    ctaMediaImage3Parallax.init();
  }
};
const handleMediaContentEvent = () => {
  const mediaContentImage1 = document.querySelector( '.e-img--media-content-img-1' );
  const mediaContentImage2 = document.querySelector( '.e-img--media-content-img-2' );
  const mediaContentImage3 = document.querySelector( '.e-img--media-content-img-3' );
  if( mediaContentImage1 ) {
    const mediaContentImage1Parallax = new ParallaxMove( mediaContentImage1, {
      offset: -40,
      headerFixed: true
    } );
    mediaContentImage1Parallax.init();
  }
  if( mediaContentImage2 ) {
    const mediaContentImage2Parallax = new ParallaxMove( mediaContentImage2, {
      offset: -60,
      headerFixed: true
    } );
    mediaContentImage2Parallax.init();
  }
  if( mediaContentImage3 ) {
    const mediaContentImage3Parallax = new ParallaxMove( mediaContentImage3, {
      offset: 50,
      headerFixed: true
    } );
    mediaContentImage3Parallax.init();
  }
};
const handleEvents = () => {
  handleCtaMediaEvent();
  handleMediaContentEvent();
};

window.addEventListener( 'load', handleEvents );
window.addEventListener( 'scroll', handleEvents );
window.addEventListener( 'resize', handleEvents );

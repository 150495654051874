/* eslint-disable no-unused-vars, no-undef */
class TOC {
  constructor( element ) {
    this.element = element;
    this.tocItemId = 'toc-item';
    this.tocTitleId = 'toc-title';
    this.hH = document.querySelector( 'header' )?.offsetHeight || 0;
    this.tocSection = element.classList.contains( 'e-stn--toc-sections' );
    this.tocContents = element.querySelectorAll( '.e-div--toc-content-wrapper' );
  }

  setTocItemActive( itemId ) {
    this.element.querySelectorAll( '[id*="toc-item-"]' ).forEach( ( item ) => {
      item.classList.remove( 'active' );
    } );
    this.element.querySelector( `#${itemId}` ).classList.add( 'active' );
  }

  setTitleActive( titleId, accordionClick = false ) {
    const title = this.element.querySelector( `#${titleId}` );
    const accordionItem = title.closest( '.e-acc__itm-wrpr' );
    const activeAccordion = this.element.querySelector( '.e-acc__itm-wrpr.active' );
    this.element.querySelectorAll( '[id*="toc-title-"]' ).forEach( ( item ) => {
      item.classList.remove( 'active' );
    } );
    title.classList.add( 'active' );
    if( accordionItem && accordionClick ) {
      accordionItem.querySelector( '.e-acc__itm-hdr-wrpr' ).click();
    } else if( activeAccordion && accordionItem !== activeAccordion ) {
      activeAccordion.querySelector( '.e-acc__itm-hdr-wrpr' ).click();
    }
  }

  setSectionActive ( sectionId = 0 ) {
    this.tocContents.forEach( ( item ) => {
      item.classList.remove( 'active' );
    } );
    this.tocContents[sectionId].classList.add( 'active' );
  }

  tocTitleClick ( titleId ) {
    const tocItemId = this.tocItemId + titleId.split( this.tocTitleId )[1];
    if( !this.tocSection ) {
      this.setTocItemActive( tocItemId );
      this.setTitleActive( titleId );
    }
  }

  tocTitleClickEvent () {
    const tocTitles = this.element.querySelectorAll( '[id*="toc-title-"]' );
    tocTitles.forEach( ( tocTitle ) => {
      tocTitle.addEventListener( 'click', () => {
        const titleId = tocTitle.id;
        this.tocTitleClick( titleId );
      } );
    } );
  }

  tocItemClick ( itemId ) {
    const tocTitleId = this.tocTitleId + itemId.split( this.tocItemId )[1];
    if( !this.tocSection ) {
      this.setTitleActive( tocTitleId, true );
    }
    this.setTocItemActive( itemId );
  }

  tocItemClickEvent () {
    const tocItems = this.element.querySelectorAll( '[id*="toc-item-"]' );
    tocItems.forEach( ( tocItem, id ) => {
      tocItem.addEventListener( 'click', () => {
        const itemId = tocItem.id;
        this.tocItemClick( itemId );
        if( this.tocSection ) {
          this.setSectionActive( id );
          this.scrollToTop( id );
        }
      } );
    } );
  }

  checkSticky () {
    if ( !this.element ) {
      return;
    }
    const vh = window.innerHeight;
    const toc = this.element.querySelector( '.e-div--toc-wrapper' ).closest( '.e-col' );
    const tocH = toc.offsetHeight;
    if ( tocH > ( vh - this.hH - 25 ) ) {
      toc.classList.add( 'remove-sticky' );
    } else {
      toc.classList.remove( 'remove-sticky' );
    }
  }

  scrollToTop ( sectionId = 0 ) {
    const secParent = this.tocContents[sectionId].closest( '.e-col' );
    const secTop = secParent.getBoundingClientRect().top;
    const hH = document.querySelector( 'header' )?.offsetHeight || 0;
    window.scrollTo( {
      top: ( secTop + window.scrollY ) - ( hH + 25 ) ,
      behavior: 'smooth'
    } );
  }
  init() {
    if ( !this.element ) {
      return;
    }
    const firstTocItem = this.element.querySelectorAll( '[id*="toc-item-"]' )[0].id;
    const firstTocTitle = this.element.querySelectorAll( '[id*="toc-title-"]' )[0].id;
    this.setTocItemActive( firstTocItem );
    this.setTitleActive( firstTocTitle );
    if( this.tocSection ) {
      this.setSectionActive();
    }
    this.checkSticky();
    this.tocTitleClickEvent();
    this.tocItemClickEvent();
  }
}

const tocEls = document.querySelectorAll( '.e-stn--toc-accordion' );
const tocElObjs = [];
tocEls.forEach( ( tocEl ) => {
  const tocObj = new TOC( tocEl );
  tocElObjs.push( tocObj );
} );
const loadHandler = () => {
  tocElObjs.forEach( ( tocElObj ) => {
    tocElObj.init();
  } );
};
const resizeHandler = () => {
  tocElObjs.forEach( ( tocElObj ) => {
    tocElObj.checkSticky();
  } );
};
window.addEventListener( 'load', loadHandler );
window.addEventListener( 'orientationchange', resizeHandler );

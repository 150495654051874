document.querySelectorAll( '.e-stn--microsite-banner .e-div--content-wrapper a' ).forEach( anchor => {
  anchor.addEventListener( 'click', function ( e ) {
    e.preventDefault(); // Prevent default anchor behavior
  
    const targetElementSelector = '.e-stn--google-map'; // Target element to scroll to

    // Smooth scroll to the target element
    const target = document.querySelector( targetElementSelector );
    const header = document.querySelector( 'header' ); // Assuming your header element is <header>
  
    if ( target ) {
      const offsetTop = target.getBoundingClientRect().top + window.pageYOffset;
      const headerHeight = header ? header.offsetHeight : 0; // Get header height, fallback to 0
      const reducedHeaderHeight = headerHeight * 1.1; // Reduce header height by 20%
  
      const adjustedPosition = offsetTop - reducedHeaderHeight;
  
      // Smooth scroll to the adjusted position
      window.scrollTo( {
        top: adjustedPosition,
        behavior: 'smooth'
      } );
    } else {
      console.error( 'Target element not found on this page' );
    }
  } );
} );
  
const contentTabsSection = {
  sections: document.querySelectorAll( '.e-stn--accordion-with-media, .e-stn--text-block-accordion' ),
  
  init() {
    if ( this.sections.length === 0 ) return;
  
    this.sections.forEach( section => {
      this.toggleImageColumns( section );
    } );
  },
  
  toggleImageColumns( section ) {
    const rows = section.querySelectorAll( '.e-div--media-with-paragraph' );
  
    rows.forEach( ( row ) => {
      const cols = row.querySelectorAll( '.e-div' );
      if ( cols.length > 1 && ( cols[1].querySelector( '.e-img' ) || cols[1].querySelector( '.e-vid' ) ) ) {
        const secondCol = cols[1];
        secondCol.classList.add( 'img-right-align' );
        row.prepend( secondCol );
      }
    } );
  }
};
  
contentTabsSection.init();

import { slideDown, slideUp } from './common';

document.addEventListener( 'DOMContentLoaded', function () {
  const duration = 600;
  const footer = document.querySelector( 'footer' );

  if ( footer ) {
    initializeFooterMenu( footer, duration );
  }
} );

function initializeFooterMenu( footer, duration ) {
  const menuItems = footer.querySelectorAll( 'ul.wp-block-navigation > .wp-block-navigation-item.has-child' );

  if ( menuItems.length > 0 ) {
    addFooterDropDownHelpers( menuItems );
    addFooterDropDownListeners( footer, duration );
  }
}

function addFooterDropDownHelpers( menuItems ) {
  menuItems.forEach( menuItem => {
    const span = document.createElement( 'span' );
    span.classList.add( 'footer-drop-down-helper' );
    menuItem.appendChild( span );
  } );
}

function addFooterDropDownListeners( footer, duration ) {
  const helpers = footer.querySelectorAll( 
    'ul.wp-block-navigation > .wp-block-navigation-item.has-child span.footer-drop-down-helper' 
  );

  helpers.forEach( helper => {
    helper.addEventListener( 'click', function ( e ) {
      handleFooterDropDownClick( e, footer, duration );
    } );
  } );
}

function handleFooterDropDownClick( event, footer, duration ) {
  const li = event.target.parentElement;
  const isExpanded = li.classList.contains( 'footer-expanded' );

  collapseExpandedItems( footer, duration );

  const subMenu = event.target.previousElementSibling;
  if ( !subMenu ) return;

  if ( isExpanded ) {
    slideUp( subMenu, duration );
    subMenu.parentElement.classList.remove( 'footer-expanded' );
  } else {
    li.classList.add( 'footer-expanded' );
    slideDown( subMenu, duration );
  }
}

function collapseExpandedItems( footer, duration ) {
  const expandedItems = footer.querySelectorAll( '.footer-expanded' );
  expandedItems.forEach( expandedItem => {
    const subMenu = expandedItem.querySelector( 'wp-block-navigation__submenu-container' );
    if ( subMenu ) {
      slideUp( subMenu, duration );
      expandedItem.classList.remove( 'footer-expanded' );
    }
  } );
}

// Helper function to handle previous active elements
const deactivatePreviousElements = ( previousElements ) => {
  if ( previousElements.childElement ) {
    previousElements.childElement.classList.remove( 'active' );
  }
};

// Main function
const initializeMenuInteraction = () => {
  const parentElement = document.querySelector( '.mega-disable-sub-menu-active' );
  if ( !parentElement ) return;
  const childElements = parentElement.querySelectorAll(
    '.mega-sub-menu .mega-menu-item .mega-menu-link'
  );
  const previousActiveElements = {
    childElement: null,
  };
  childElements.forEach( ( linkElement ) => {
    linkElement.addEventListener( 'click', () => {
      deactivatePreviousElements( previousActiveElements );
      linkElement.classList.add( 'active' );
      previousActiveElements.childElement = linkElement;
    } );
  } );
};

initializeMenuInteraction();

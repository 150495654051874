const searchPopup = {
  dom: document.querySelector( '.e-div--header-search' ),

  init() {
    if ( !this.dom ) return;

    const trigger = this.dom.querySelector( ':scope > .e-icn' );
    const popupDOM = this.dom.querySelector( ':scope > .e-div' );
    const inputField = this.dom.querySelector( '.wp-block-search__input' );

    const closeBtn = document.createElement( 'span' );
    closeBtn.classList.add( 'close-btn' );
    popupDOM.appendChild( closeBtn );

    closeBtn.addEventListener( 'click', () => {
      this.dom.classList.remove( 'opened' );
      inputField.value = '';
    } );

    trigger.addEventListener( 'click', () => {
      inputField.value = '';
      this.dom.classList.add( 'opened' );
      inputField.focus();
    } );
    
  }
};
searchPopup.init();

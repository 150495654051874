
function setupFormInteractions() {
  const formContainer = document.querySelector( '.e-div--forms-common' );
  if ( !formContainer ) return;

  document.addEventListener( 'load', handleInputEvent );
  document.addEventListener( 'change', handleInputEvent );
  document.addEventListener( 'input', handleInputEvent );
  document.addEventListener( 'click', handleInputEvent );
  document.addEventListener( 'blur', handleInputEvent );
  document.addEventListener( 'focus', handleInputEvent );
  document.addEventListener( 'focusout', handleFocusOutEvent );
  
  // dynamic textarea
  dynamicTextArea( formContainer );

}

function handleInputEvent( e ) {
  const tag = e.target.tagName == 'INPUT' || e.target.tagName == 'TEXTAREA';
  if ( e.target?.classList && e.target.classList.contains( 'hs-input' ) && tag ) {
    e.target.closest( '.field' ).classList.add( 'active' );
  }
}

function handleFocusOutEvent( e ) {
  if ( e.target?.classList && e.target.classList.contains( 'hs-input' ) ) {
    if ( e.target.value == '' ) {
      e.target.closest( '.field' ).classList.remove( 'active' );
    } else {
      e.target.closest( '.field' ).classList.add( 'active' );
    }
  }
}

function toggleActive ( node, value ) {
  if ( value == '' ) {
    node?.classList.remove( 'active' );
  } else {
    node?.classList.add( 'active' );
  }
}

function dynamicTextArea ( container ) {
  const observer = new MutationObserver( mutations => {
    mutations.forEach( mutation => {
      if ( mutation.type === 'childList' ) {
        mutation.addedNodes.forEach( node => {
          // Ensure it's an element node
          const textArea = node.nodeType === 1 ? node.querySelector( '.hs-fieldtype-textarea' ) : null;
          if ( textArea ) {
            const textVal = textArea.value;
            toggleActive( node, textVal );
          }
        } );
      }
    } );
  } );
  
  observer.observe( container, { childList: true, subtree: true } );
}

document.addEventListener( 'DOMContentLoaded', () => {
  setupFormInteractions();
} );

window.addEventListener( 'blur', function () {
  const fields = this.document.querySelectorAll( 'input, textarea' );
  fields.forEach( ( field ) => {
    field.blur();
  } );
} );

window.addEventListener( 'message', function( event ) {
  if ( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted' ) {
    // Ensure no validation errors
    setTimeout( () => {
      const errorMessages = document.querySelectorAll( '.hs-error-msg, .hs-form-error' );
      if ( errorMessages.length === 0 ) {
        window.scrollTo( { top: 0, behavior: 'smooth' } );
      }
    }, 100 );
  }

  if( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
    document.querySelector( 'form.hs-form' ).setAttribute( 'autocomplete', 'off' );
    document.querySelectorAll( 'input.hs-input' ).forEach( input => {
      input.setAttribute( 'autocomplete', 'off' );
    } );
  }
} );

( () => {
  const imgCarousel = {
    popupOpened: false,
    carousels: document.querySelectorAll( '.e-div--image-carousel' ),

    // Start all carousel animations
    startAllCarousels() {
      if ( !this.popupOpened ) {
        this.carousels.forEach( carousel => carousel.classList.remove( 'pause' ) );
      }
    },

    // Pause all carousel animations
    pauseAllCarousels() {
      this.carousels.forEach( carousel => carousel.classList.add( 'pause' ) );
    },

    /**
     * Open image popup
     * @param {Element} image Clicked image element
     */
    openPopup( image ) {
      const slide = image.closest( '.swiper-slide' ) ?? image.closest( '.e-col' );
      const popupContent = `
        <div class="image-popup__wrapper">
          <span class="close-popup"></span>${image.outerHTML}
        </div>`;

      let popupDOM = slide.querySelector( '.image-popup' ) || document.createElement( 'div' );

      if ( !popupDOM.parentElement ) {
        popupDOM.classList.add( 'image-popup' );
        slide.appendChild( popupDOM );
      }

      popupDOM.innerHTML = popupContent;
      popupDOM.classList.add( 'active' );
      this.popupOpened = true;

      popupDOM.querySelector( '.close-popup' ).addEventListener( 'click', () => {
        this.closePopup();
      } );
    },

    closePopup() {
      document.querySelectorAll( '.image-popup' ).forEach( popup => {
        popup.classList.remove( 'active' );
      } );
      this.popupOpened = false;
      this.startAllCarousels();
    },

    /**
     * Bind hover and click events to images
     * @param {Element} images All images inside a carousel
     */
    bindEvents( images ) {
      images.forEach( image => {
        const carousel = image.closest( '.e-div--image-carousel' );

        image.addEventListener( 'click', () => {
          this.pauseAllCarousels();
          this.openPopup( image );
        } );

        image.addEventListener( 'mouseenter', () => carousel.classList.add( 'pause' ) );
        image.addEventListener( 'mouseleave', () => {
          if ( !this.popupOpened ) carousel.classList.remove( 'pause' );
        } );
      } );
    },

    // Bind slider interaction events
    onSliderInteraction() {
      const onchange = ( slider ) => {        
        slider.swiper.on( 'slideChange', () => {
          this.closePopup();
          this.popupOpened = false;
          this.startAllCarousels();
        } );
      };
      const sliderEventBind = setInterval( () => {
        const sliders = document.querySelectorAll( '.e-stn--tab-slider .swiper-initialized' );
        if ( sliders.length > 0 ) {
          sliders.forEach( slider => {
            onchange( slider );
          } );
          clearInterval( sliderEventBind );
        }
      }, 100 );
    },

    init() {
      // Remove lazyloading in Safari
      if ( navigator.userAgent.indexOf( 'Safari' ) != -1 && navigator.userAgent.indexOf( 'Chrome' ) == -1 ) {
        document.querySelectorAll( '.e-stn--tab-slider img[loading="lazy"]' ).forEach( ( img ) => {
          img.removeAttribute( 'loading' );
        } );
      }

      this.carousels.forEach( carousel => {
        const track = carousel.querySelector( ':scope > .e-div' );
        if ( !track ) return;

        const numClones = Math.ceil( carousel.offsetWidth / track.offsetWidth ) * 3;
        for ( let i = 0; i < numClones; i++ ) {
          carousel.appendChild( track.cloneNode( true ) );
        }

        carousel.querySelectorAll( ':scope > .e-div' ).forEach( clonedTrack => {
          clonedTrack.style.animationDuration = `${60 - numClones}s`;
          this.bindEvents( clonedTrack.querySelectorAll( '.e-img' ) );
        } );
      } );
    }
  };

  imgCarousel.init();
  imgCarousel.onSliderInteraction();
} )();

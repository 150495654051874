function setupFormInteractions() {
  const formContainer = document.querySelector( '.e-div--gravity-form' );
  if ( !formContainer ) return;

  const classesToCheck = ['medium', 'large', 'small'];

  function checkInitialInputState() {
    const inputs = formContainer.querySelectorAll( 
      'input.medium, input.large, textarea.large, textarea.medium, textarea.small' );
    inputs.forEach( ( input ) => {
      if ( input.value.trim() !== '' ) {
        toggleActiveClass( input, true );
      }
    } );
  }

  function toggleActiveClass( element, isActive ) {
    const gfield = element.closest( '.gfield' );
    if ( gfield ) {
      gfield.classList.toggle( 'active', isActive );
    }
  }

  function handleInputEvent( e ) {
    const target = e.target;
    if ( target?.classList && classesToCheck.some( ( cls ) => target.classList.contains( cls ) ) ) {
      toggleActiveClass( target, true );
    }
  }

  function handleFocusOutEvent( e ) {
    const target = e.target;
    if ( target?.classList && classesToCheck.some( ( cls ) => target.classList.contains( cls ) ) ) {
      toggleActiveClass( target, target.value.trim() !== '' );
    }
  }

  const events = ['load', 'input', 'change', 'click', 'blur', 'focus']; 
  events.forEach( ( event ) => {
    document.addEventListener( event, handleInputEvent );
  } );

  document.addEventListener( 'focusout', handleFocusOutEvent );
  document.addEventListener( 'gform/post_render', () => {
    checkInitialInputState();
  } );
  window.addEventListener( 'blur', function () {
    const fields = this.document.querySelectorAll( 'input' );
    fields.forEach( ( field ) => {
      field.blur();
    } );
  } );
}

document.addEventListener( 'DOMContentLoaded', setupFormInteractions );
window.addEventListener( 'pageshow', function () {
  setupFormInteractions();
} );

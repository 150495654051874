import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';

document.addEventListener( 'DOMContentLoaded', function () {
  const images = document.querySelectorAll( '.e-stn--national-photo-gallery .wp-block-image' );
  const initialItems = 40;

  initializeGallery( images, initialItems );

  const loadMoreButton = document.querySelector( '.e-btn--gallery-load-more' );
  if ( loadMoreButton ) {
    loadMoreButton.addEventListener( 'click', ( event ) => 
      handleLoadMore( event, images, initialItems, loadMoreButton ) );
  }

  bindFancybox();
} );

// Function to initialize the gallery by hiding excess images
function initializeGallery( images, initialItems ) {
  hideExcessImages( images, initialItems );
  images.forEach( ( img, index ) => initializeFancyboxForImage( img, index, images ) );
}

// Function to hide images beyond the initial count
function hideExcessImages( images, initialItems ) {
  images.forEach( ( img, index ) => {
    if ( index >= initialItems ) {
      img.style.display = 'none';
    }
  } );
}

// Function to handle the "Load More" button click
function handleLoadMore( event, images, initialItems, loadMoreButton ) {
  event.preventDefault();
  const scrollPosition = window.scrollY;

  let moreImagesVisible = showMoreImages( images, initialItems );
  if ( moreImagesVisible ) {
    const allImagesVisible = Array.from( images ).every( ( img, index ) =>
      index < initialItems || img.style.display === 'block'
    );

    if ( allImagesVisible ) {
      loadMoreButton.style.display = 'none';
    }
  }

  window.scrollTo( 0, scrollPosition );
}

// Function to show additional images beyond the initial count
function showMoreImages( images, initialItems ) {
  let moreImagesVisible = false;

  images.forEach( ( img, index ) => {
    if ( index >= initialItems ) {
      img.style.display = 'block';
      moreImagesVisible = true;
      initializeFancyboxForImage( img, index, images );
    }
  } );

  return moreImagesVisible;
}

// Function to bind Fancybox to images
function bindFancybox() {
  Fancybox.bind( '[data-fancybox="gallery"]', {
    mainClass: 'gallery-popup',
    Toolbar: {
      display: [{ id: 'close' }],
    },
    Thumbs: false,
    transitionEffect: 'none',
    transitionDuration: 0,
    animationDuration: 0,
    animationEffect: 'none',
    animated: false,
    hash: false,
  } );
}

// Function to initialize Fancybox for a single image
function initializeFancyboxForImage( img, index, images ) {
  const imageElement = img.querySelector( 'img' );
  const captionElement = img.querySelector( 'figcaption.wp-element-caption' );

  const anchor = document.createElement( 'a' );
  anchor.href = imageElement.src;
  anchor.classList.add( 'fancybox' );
  anchor.setAttribute( 'data-fancybox', 'gallery' );

  if ( captionElement ) {
    anchor.setAttribute( 'data-caption',
      `<span class="image-title">${captionElement.innerHTML}</span>
       <span class="image-count">Image ${index + 1} of ${images.length}</span>` );
    const captionClone = captionElement.cloneNode( true );
    anchor.appendChild( captionClone );
  }

  anchor.prepend( imageElement.cloneNode( true ) );
  img.innerHTML = '';
  img.appendChild( anchor );
}

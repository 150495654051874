const setViewportHeight = () => {
  const vh = window.innerHeight;
  const mainBanner = document.querySelector( '.e-stn--main-hero-banner' );
  if( mainBanner ) {
    mainBanner.style.setProperty( 'min-height', `${vh}px` );
  }
};

// Call the function initially
setViewportHeight();

// Recalculate the height on orientation change
window.addEventListener( 'orientationchange', setViewportHeight );
